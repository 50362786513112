/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useRef,
	useState,
	FormEvent,
	useContext,
	ChangeEvent,
	KeyboardEvent,
	ChangeEventHandler,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	DateField,
	TextButton,
	FormFooter,
	SelectField,
	GenericField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { ASSAYS, ORGANIZATIONS } from '@pangea-lis-apps/utils';

import {
	usePopulateFormValues,
	normalizeAccessioningFormValues,
} from '../../trf/utils/helpers';
import { ProcessSampleContext } from '../process-sample';
import FlagButton from '../../../../../../../components/flag-button';

interface FormValues {
	tracking_number: string;
	priority: string;
	specimen_id: string;
	collection_kit_id: string;
	sample_received_date: string;
	sample_transport_storage_condition: string;
	flag: {
		priority: boolean;
		tracking_number: boolean;
		collection_kit_id: boolean;
		sample_received_date: boolean;
		sample_transport_storage_condition: boolean;
	};
}

const initialFormValues = {
	tracking_number: '',
	priority: '',
	specimen_id: '',
	collection_kit_id: '',
	sample_received_date: '',
	sample_transport_storage_condition: '',
	flag: {
		priority: false,
		tracking_number: false,
		collection_kit_id: false,
		sample_received_date: false,
		sample_transport_storage_condition: false,
	},
};

export default function FITSampleUpdate() {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { assay, dataId } = useParams();
	const { data } = useContext(ProcessSampleContext);

	const formValuesRef = useRef<FormValues>();
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'sample'
	);

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const handleUpdateSample = async (event?: FormEvent) => {
		if (event) event.preventDefault();

		const redirectURL = `/accessioner/accession/sample/${assay}/data/${dataId}/sample/print-barcode${redirectPathQuery}`;

		if (disabled || !axios || !data) return;
		else if (
			JSON.stringify(formValuesRef.current) === JSON.stringify(formValues)
		) {
			navigate(redirectURL);
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'sample'
			);

			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample`, {
				user,
				assay,
				form_data: normalizedFormValues,
			});

			toast.dismiss();

			navigate(redirectURL);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues) => {
				return {
					...prevValues,
					[target.name]: target.value,
				};
			});
	};

	return data ? (
		<ContentSection
			heading="Update sample info"
			className="bg-gray-50 border border-gray-200 py-8 rounded-lg"
		>
			<Form handleSubmit={handleUpdateSample}>
				<FormGroup>
					<div className="sm:col-span-2">
						<DateField
							showRequiredAsterisk={true}
							label="Received Date"
							min={
								data.sample.sample_collection_date &&
								(data.sample.sample_collection_date as string)
							}
							name="sample_received_date"
							handleInputChange={handleChange}
							value={formValues['sample_received_date']}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="sample_received_date"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Tracking Number"
							name="tracking_number"
							placeholder="e.g., XZ12345678"
							value={formValues['tracking_number']}
							handleKeyDown={(
								event: KeyboardEvent<HTMLInputElement>
							) => {
								if (event.code === 'Enter')
									event.preventDefault();
							}}
							handleInputChange={handleChange}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="tracking_number"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Collection Kit ID"
							name="collection_kit_id"
							placeholder="e.g., XZ12345678"
							value={formValues['collection_kit_id']}
							handleInputChange={handleChange}
							handleKeyDown={(
								event: KeyboardEvent<HTMLInputElement>
							) => {
								if (event.code === 'Enter')
									event.preventDefault();
							}}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="collection_kit_id"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Specimen ID"
							name="specimen_id"
							placeholder="e.g., XZ12345678"
							value={formValues['specimen_id']}
							handleInputChange={handleChange}
							handleKeyDown={(
								event: KeyboardEvent<HTMLInputElement>
							) => {
								if (event.code === 'Enter')
									event.preventDefault();
							}}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="specimen_id"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							name="priority"
							handleSelect={handleChange}
							label="Change Priority Level?"
							value={formValues['priority']}
							options={ORGANIZATIONS.priorityLevels}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							required
							handleSelect={handleChange}
							options={ASSAYS['fit'].transport_storage_conditions}
							label="Transport Storage Condition?"
							name="sample_transport_storage_condition"
							value={
								formValues['sample_transport_storage_condition']
							}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="sample_transport_storage_condition"
								/>
							}
						/>
					</div>
				</FormGroup>
				<FormFooter className="!justify-between">
					<TextButton
						color="gray"
						type="button"
						onClick={() =>
							navigate(
								`/accessioner/accession/trf/fit/v${data.requisition_form.metadata.version}/data/${dataId}/trf/review`
							)
						}
						text={
							<span>
								&#8592; Return to{' '}
								<span className="italic">Process TRF</span>
							</span>
						}
					/>
					<Button
						type="submit"
						tier="tertiary"
						text="Continue"
						disabled={disabled}
						Icon="ArrowSmallRightIcon"
					/>
				</FormFooter>
			</Form>
		</ContentSection>
	) : null;
}
