export function FindDiff(oldObject: any, newObject: any) {
	let revisionKeys = '';
	const diff = Object();
	const samplesKeyList = [
		'ruo',
		'sample_received_date',
		'specimen_id',
		'sample_collection_date',
		'collection_kit_id',
	];

	for (const key in newObject) {
		if (newObject[key] !== oldObject[key]) {
			const fieldName = key.toLowerCase().split('_').join(' ');

			revisionKeys =
				revisionKeys === ''
					? fieldName
					: revisionKeys.concat(', ', fieldName);

			const prefix = samplesKeyList.includes(key)
				? 'sample.'
				: 'requisition_form.';
			const diffkey = prefix + key;
			diff[diffkey] = newObject[key];
		}
	}

	const revisedReportDate = new Date().toLocaleString().split(',')[0];
	const revisionComment = `REVISED REPORT for ${revisionKeys}. THE RESULTS REMAIN UNCHANGED. ${revisedReportDate}`;

	if (Object.keys(diff).length === 0) {
		console.log('Nothing change');
		return;
	}

	const payload = {
		updated_values: diff,
		revision_comment: revisionComment,
	};

	return payload;
}
