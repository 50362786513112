/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Fragment,
	useState,
	useEffect,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	FormGroup,
	DateField,
	LoadingBox,
	SelectField,
	GenericField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { FORMS, Option } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';

interface FormValues {
	requisition_form_received_date: string;
	provider_first_name: string;
	provider_last_name: string;
	provider_national_provider_identifier: string;
	organization_name: string;
	organization_national_provider_identifier: string;
	organization_address_address: string;
	organization_address_address2: string;
	organization_address_city: string;
	organization_address_state: string;
	organization_address_zip_code: string;
	organization_address_country: string;
	organization_phone_number: string;
	flag: {
		requisition_form_received_date: boolean;
		provider_first_name: boolean;
		provider_last_name: boolean;
		provider_national_provider_identifier: boolean;
		organization_name: boolean;
		organization_national_provider_identifier: boolean;
		organization_address_address: boolean;
		organization_address_address2: boolean;
		organization_address_city: boolean;
		organization_address_state: boolean;
		organization_address_zip_code: boolean;
		organization_address_country: boolean;
		organization_phone_number: boolean;
	};
}

const initialFormValues = {
	requisition_form_received_date: '',
	provider_first_name: '',
	provider_last_name: '',
	provider_national_provider_identifier: '',
	organization_name: '',
	organization_national_provider_identifier: '',
	organization_address_address: '',
	organization_address_address2: '',
	organization_address_city: '',
	organization_address_state: '',
	organization_address_zip_code: '',
	organization_address_country: '',
	organization_phone_number: '',
	flag: {
		requisition_form_received_date: false,
		provider_first_name: false,
		provider_last_name: false,
		provider_national_provider_identifier: false,
		organization_name: false,
		organization_national_provider_identifier: false,
		organization_address_address: false,
		organization_address_address2: false,
		organization_address_city: false,
		organization_address_state: false,
		organization_address_zip_code: false,
		organization_address_country: false,
		organization_phone_number: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProviderOrganizationProps {}

export default function ProviderOrganization(props: ProviderOrganizationProps) {
	const {
		data,
		formValues,
		formValuesRef,
		setFormValues,
		handleSubmit,
		formId,
	} = useContext(ProcessTRFStepsContext);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]: target.value,
					};
				}

				return prevValues;
			});
	};

	const [stateSelection, setStateSelection] = useState('');

	useEffect(() => {
		if (formValues && !stateSelection) {
			if (
				formValues['organization_address_state'] !== '' &&
				!FORMS.states
					.map((option: Option) => option.value)
					.includes(formValues.organization_address_state)
			) {
				setStateSelection('other');
			} else {
				setStateSelection(formValues.organization_address_state);
			}
		}
	}, [formValues, stateSelection]);

	const handleStateSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setStateSelection(target.value);

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return !(formValues && data) ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<Form id={formId} handleSubmit={handleSubmit}>
				<Fragment>
					<FormGroup heading="Requisition form information">
						<div className="sm:col-span-2">
							<DateField
								showRequiredAsterisk={true}
								label="Received Date"
								name="requisition_form_received_date"
								handleInputChange={handleChange}
								value={
									formValues['requisition_form_received_date']
								}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="requisition_form_received_date"
									/>
								}
							/>
						</div>
					</FormGroup>
					<FormGroup heading="Provider Info">
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								showRequiredAsterisk={true}
								label="First Name"
								placeholder="e.g., Sarah"
								name="provider_first_name"
								handleInputChange={handleChange}
								value={formValues.provider_first_name}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="provider_first_name"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Last Name"
								showRequiredAsterisk={true}
								placeholder="e.g., Sarah"
								name="provider_last_name"
								value={formValues.provider_last_name}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="provider_last_name"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								disabled
								type="text"
								maxLength={10}
								placeholder="e.g., XZ1234567890"
								showRequiredAsterisk={true}
								label="National Provider Identifier (NPI)"
								name="provider_national_provider_identifier"
								value={
									formValues.provider_national_provider_identifier
								}
								handleInputChange={handleChange}
							/>
						</div>
					</FormGroup>

					<FormGroup heading="Organization Info">
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								name="organization_name"
								label="Name"
								showRequiredAsterisk={true}
								placeholder="e.g., Wellthy Medical"
								value={formValues.organization_name}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_name"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								disabled
								type="text"
								maxLength={10}
								placeholder="e.g., XZ1234567890"
								label="National Provider Identifier (NPI)"
								name="organization_national_provider_identifier"
								value={
									formValues.organization_national_provider_identifier
								}
								handleInputChange={handleChange}
							/>
						</div>
						<div className="sm:col-span-1"></div>
						<div className="sm:col-span-4">
							<GenericField
								type="text"
								label="Address"
								showRequiredAsterisk={true}
								placeholder="e.g., 123 Main St."
								name="organization_address_address"
								value={formValues.organization_address_address}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_address"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-4">
							<GenericField
								type="text"
								label="Address Line 2"
								placeholder="e.g., Suite 101"
								name="organization_address_address2"
								value={formValues.organization_address_address2}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_address2"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="City"
								placeholder="e.g., Tustin"
								showRequiredAsterisk={true}
								name="organization_address_city"
								value={formValues.organization_address_city}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_city"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="State"
								name="organization_address_state"
								options={FORMS.states}
								showRequiredAsterisk={true}
								handleSelect={handleStateSelectionChange}
								value={stateSelection}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_state"
									/>
								}
							/>
						</div>
						{stateSelection === 'other' ? (
							<div className="sm:col-span-2">
								<GenericField
									required
									type="text"
									label="If other, please specify"
									name="organization_address_state"
									placeholder="e.g., New York"
									value={
										formValues.organization_address_state
									}
									handleInputChange={handleChange}
								/>
							</div>
						) : (
							<div className="sm:col-span-1"></div>
						)}
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="ZIP Code"
								placeholder="e.g., 18293"
								showRequiredAsterisk={true}
								name="organization_address_zip_code"
								value={formValues.organization_address_zip_code}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_zip_code"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="Country"
								name="organization_address_country"
								options={FORMS.countries}
								showRequiredAsterisk={true}
								handleSelect={handleChange}
								value={formValues.organization_address_country}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_address_country"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Phone Number"
								showRequiredAsterisk={true}
								placeholder="e.g., +1 (234) 567-8901"
								name="organization_phone_number"
								value={formValues.organization_phone_number}
								handleInputChange={handleChange}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="organization_phone_number"
									/>
								}
							/>
						</div>
					</FormGroup>
				</Fragment>
			</Form>
		</ContentSection>
	);
}
