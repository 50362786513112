import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	useRef,
	Fragment,
	useState,
	FormEvent,
	useEffect,
	ChangeEvent,
	FormEventHandler,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Label,
	Button,
	useAxios,
	FormGroup,
	Container,
	FileField,
	FormFooter,
	TextButton,
	SelectField,
	GenericField,
	ContentSection,
	ContentWrapper,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	ORGANIZATIONS,
	normalizeFormValues,
} from '@pangea-lis-apps/utils';

const formatFileSize = (bytes: number) => {
	let unitIndex = 0;
	const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	while (bytes >= 1024 && unitIndex < units.length - 1) {
		bytes /= 1024;
		unitIndex++;
	}

	return `${Math.round(bytes)} ${units[unitIndex]}`;
};

interface FormValues {
	logo_image: File | undefined;
	entity: string;
	name: string;
	priority: string;
	email_address: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	phone_number: string;
	national_provider_identifier: string;
}

const initialFormValues = {
	logo_image: undefined,
	entity: '',
	name: '',
	priority: '',
	email_address: '',
	address: '',
	address2: '',
	city: '',
	state: '',
	zip_code: '',
	country: '',
	phone_number: '',
	national_provider_identifier: '',
};

const testInitialFormValues = {
	logo_image: undefined,
	entity: 'non_clinic',
	name: 'Fair View Labs',
	priority: '1',
	email_address: 'test.pangealab+fairViewLabs@gmail.com',
	address: '123 Main St.',
	address2: 'Suite 600',
	city: 'Tustin',
	state: 'California',
	zip_code: '92828',
	country: 'United States of America',
	phone_number: '+1 (123) 345-6738',
	national_provider_identifier: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateOrganizationProps {}

export default function CreateOrganization(props: CreateOrganizationProps) {
	const toastId = useId();
	const toastOptions = { id: toastId };

	const navigate = useNavigate();
	const axios = useAxios(toastId);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(
		process.env['NX_ENV'] === 'DEVELOPMENT'
			? testInitialFormValues
			: initialFormValues
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setFormValues((prevValues) => {
				const value = target.value;

				return {
					...prevValues,
					[target.name]: value,
				};
			});
		}
	};

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (
		event: FormEvent
	) => {
		event.preventDefault();

		if (disabled || !axios) return;
		else if (process.env['NX_ENV'] === 'development') {
			const [username, domain] = formValues.email_address.split('@');

			if (
				![
					'pangealab.com',
					'zymoresearch.com',
					'epimorphy.com',
				].includes(domain)
			) {
				if (username.includes('test.pangealab')) {
					// pass
				} else {
					toast.error(
						'User must be a Pangea Lab, Epimorphy, or Zymo Research associate!'
					);
					return;
				}
			}
		}

		setDisabled(true);

		toast.loading('Creating...', toastOptions);

		try {
			const normalizedFormValues = normalizeFormValues(formValues);

			// Need FormData object because image might be provided in the payload
			const formData = new FormData();

			formData.append(
				'organization_data',
				JSON.stringify(normalizedFormValues)
			);

			// Add any attachments if they exist
			if (
				'logo_image' in normalizedFormValues &&
				normalizedFormValues['logo_image']
			)
				formData.append(
					`logo_image`,
					normalizedFormValues['logo_image']
				);

			const {
				data: { data },
			} = await (
				await axios
			).post('/api/accounts/organization', formData);

			toast.dismiss();

			const parsedData = JSON.parse(data);
			console.log(parsedData);

			navigate(`/accounts/view/organizations/${parsedData.$oid}`);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	const handleFileChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		if (target && target.files && formValues) {
			const uploadedFiles = target.files;

			if (uploadedFiles.length === 0) {
				setFormValues((prevValues) => {
					if (prevValues)
						return {
							...prevValues,
							logo_image: undefined,
						};

					return prevValues;
				});

				return;
			}

			setFormValues((prevValues) => {
				if (prevValues)
					return {
						...prevValues,
						logo_image: uploadedFiles[0],
					};

				return prevValues;
			});
		}
	};

	const handleRemoveFile = () => {
		// Update state
		if (formValues && formValues.logo_image) {
			setFormValues((prevValues) => {
				if (prevValues)
					return {
						...prevValues,
						logo_image: undefined,
					};

				return prevValues;
			});

			// Update fileInput
			if (fileInputRef && fileInputRef.current) {
				const dataTransfer = new DataTransfer();
				fileInputRef.current.files = dataTransfer.files;
			}
		}
	};

	const [stateSelection, setStateSelection] = useState('');

	useEffect(() => {
		if (formValues && !stateSelection) {
			if (
				formValues['state'] !== '' &&
				!FORMS.states
					.map((option: Option) => option.value)
					.includes(formValues.state)
			) {
				setStateSelection('other');
			} else {
				setStateSelection(formValues.state);
			}
		}
	}, [formValues, stateSelection]);

	const handleStateSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setStateSelection(target.value);

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return (
		<Container>
			<ContentWrapper Icon="UserGroupIcon" heading="Create organization">
				<ContentSection id="form">
					<Form handleSubmit={handleSubmit}>
						<FormGroup
							heading="Branding"
							description="Only use images you are authorized to use."
						>
							{formValues.logo_image ? (
								<div className="sm:col-span-6 space-y-2">
									<Label
										name="selected_image"
										label="Selected image"
									/>
									<div className="sm:col-span-6 space-y-10">
										<div className="space-y-2">
											<div className="flex items-start space-x-4">
												<img
													width="200"
													height="200"
													alt="Organization logo"
													src={URL.createObjectURL(
														formValues.logo_image
													)}
													className="rounded-lg overflow-hidden shadow-sm"
												/>
												<div className="w-full flex items-start justify-between py-1">
													<div className="space-y-1">
														<p className="font-medium">
															{
																formValues
																	.logo_image
																	.name
															}
														</p>
														<p className="text-sm">
															{formatFileSize(
																formValues
																	.logo_image
																	.size
															)}
														</p>
													</div>
													<TextButton
														color="blue"
														type="button"
														text="Remove"
														className="group-hover:text-gray-700"
														onClick={() =>
															handleRemoveFile()
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="sm:col-span-2">
									<FileField
										accept="image/*"
										name="logo_image"
										label="Logo image"
										ref={fileInputRef}
										handleInputChange={handleFileChange}
									/>
								</div>
							)}
						</FormGroup>

						<hr />

						<FormGroup
							heading="Entity"
							description="If the samples provided by the organization will be provided with a requisition form, then select clinic."
						>
							<div className="sm:col-span-2">
								<SelectField
									required
									name="entity"
									label="Entity"
									value={formValues.entity}
									handleSelect={handleChange}
									options={ORGANIZATIONS.types}
								/>
							</div>
						</FormGroup>
						<FormGroup
							heading="Priority"
							description="This sets the default priority level of all samples sent by this organization. From highest to lowest priority: STAT, 1, and 2."
						>
							<div className="sm:col-span-2">
								<SelectField
									required
									name="priority"
									label="Priority"
									value={formValues.priority}
									handleSelect={handleChange}
									options={ORGANIZATIONS.priorityLevels}
								/>
							</div>
						</FormGroup>
						<FormGroup heading="Details">
							<div className="sm:col-span-2">
								<GenericField
									required
									type="text"
									name="name"
									label="Name"
									value={formValues.name}
									handleInputChange={handleChange}
									placeholder="e.g., FairLife Medical"
								/>
							</div>
							{formValues.entity === 'clinic' ? (
								<Fragment>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											maxLength={10}
											placeholder="e.g., 0123456789"
											name="national_provider_identifier"
											label="National Provider Identification (NPI)"
											value={
												formValues.national_provider_identifier
											}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2"></div>
								</Fragment>
							) : (
								<div className="sm:col-span-4"></div>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="email"
									name="email_address"
									label="Email Address"
									value={formValues.email_address}
									handleInputChange={handleChange}
									placeholder="e.g., contact@fairlifemedical.com"
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									name="phone_number"
									label="Phone Number"
									value={formValues.phone_number}
									handleInputChange={handleChange}
									placeholder="e.g., +1 (234) 567-8901"
								/>
							</div>
							<div className="sm:col-span-2"></div>
							<div className="sm:col-span-4">
								<GenericField
									type="text"
									name="address"
									label="Address"
									value={formValues.address}
									placeholder="e.g., 1234 Main St."
									handleInputChange={handleChange}
								/>
							</div>
							<div className="sm:col-span-2"></div>
							<div className="sm:col-span-4">
								<GenericField
									type="text"
									name="address2"
									label="Address Line 2"
									value={formValues.address2}
									placeholder="e.g., Suite 101"
									handleInputChange={handleChange}
								/>
							</div>
							<div className="sm:col-span-2"></div>
							<div className="sm:col-span-2">
								<GenericField
									name="city"
									type="text"
									label="City"
									value={formValues.city}
									placeholder="e.g., Albany"
									handleInputChange={handleChange}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="State"
									name="state"
									options={FORMS.states}
									handleSelect={handleStateSelectionChange}
									value={stateSelection}
								/>
							</div>
							{stateSelection === 'other' ? (
								<div className="sm:col-span-2">
									<GenericField
										required
										type="text"
										label="If other, please specify"
										name="state"
										placeholder="e.g., New York"
										value={formValues.state}
										handleInputChange={handleChange}
									/>
								</div>
							) : (
								<div className="sm:col-span-2"></div>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									name="zip_code"
									label="ZIP Code"
									placeholder="e.g., 92830"
									value={formValues.zip_code}
									handleInputChange={handleChange}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="Country"
									name="country"
									options={FORMS.countries}
									handleSelect={handleChange}
									value={formValues.country}
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								type="submit"
								text="Create"
								tier="tertiary"
								Icon="PlusIcon"
								disabled={disabled}
							/>
						</FormFooter>
					</Form>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
