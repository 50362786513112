/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	useRef,
	Fragment,
	useState,
	useContext,
	ChangeEvent,
	KeyboardEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	Container,
	DateField,
	FormFooter,
	LoadingBox,
	TextButton,
	GenericField,
	ContentSection,
	ContentWrapper,
} from '@pangea-lis-apps/ui';

import { ProcessNonClinicSamplesContext } from './form-wrapper';
import FlagButton from '../../../../../../components/flag-button';
import {
	normalizeAccessioningFormValues,
	usePopulateFormValues,
} from '../../clinic-samples/trf/utils/helpers';
import IdOverwriteConfirmationModal from '../../helpers/specimen-id-overwrite-confirmation-modal';

interface FormValues {
	collection_kit_id: string;
	pangea_id: string;
	specimen_id: string;
	tracking_number: string;
	sample_received_date: string;
	sample_collection_date: string;
	'misc.added_fields.first_name': string;
	'misc.added_fields.last_name': string;
	'misc.added_fields.date_of_birth': string;
	flag: {
		collection_kit_id: boolean;
		specimen_id: boolean;
		tracking_number: boolean;
		sample_received_date: boolean;
		sample_collection_date: boolean;
	};
}

const initialFormValues = {
	collection_kit_id: '',
	pangea_id: '',
	specimen_id: '',
	tracking_number: '',
	sample_received_date: '',
	sample_collection_date: '',
	'misc.added_fields.first_name': '',
	'misc.added_fields.last_name': '',
	'misc.added_fields.date_of_birth': '',
	flag: {
		collection_kit_id: false,
		specimen_id: false,
		tracking_number: false,
		sample_received_date: false,
		sample_collection_date: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProcessNonClinicBladderCARESampleProps {}

export default function ProcessNonClinicBladderCARESample(
	props: ProcessNonClinicBladderCARESampleProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();

	const [disabled, setDisabled] = useState(false);
	const {
		data,
		formValues,
		setFormValues,
		formValuesRef,
		redirectPathQuery,
	} = useContext(ProcessNonClinicSamplesContext);

	// For overwriting ids
	const pangeaIdOverwriteConfirmationRef = useRef(false);
	const [
		showIdOverwriteConfirmationModal,
		setShowIdOverwriteConfirmationModal,
	] = useState(false);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'sample'
	);

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]: target.value,
					};
				}

				return prevValues;
			});
	};

	const handleSubmit = async () => {
		if (disabled || !axios || !formValues || !data) return;

		const redirectURL = `/accessioner/accession/sample/bladdercare/data/${data._id.$oid}/non-clinic-sample/print-barcode${redirectPathQuery}`;

		if (
			JSON.stringify(formValuesRef.current) === JSON.stringify(formValues)
		) {
			navigate(redirectURL);
			return;
		} else if (
			formValues['sample_received_date'] &&
			formValues['sample_collection_date'] &&
			new Date(formValues['sample_received_date']) <
				new Date(formValues['sample_collection_date'])
		) {
			toast.error(
				'Invalid received date! Received Date must be after the collection date.'
			);
			return;
		} else if (data) {
			if (
				data.sample.specimen_id &&
				formValues['specimen_id'] !== data.sample.specimen_id
			) {
				if (!pangeaIdOverwriteConfirmationRef.current) {
					setShowIdOverwriteConfirmationModal(true);
					return;
				}

				pangeaIdOverwriteConfirmationRef.current = false;
				setShowIdOverwriteConfirmationModal(false);
			}
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'sample'
			);

			await (
				await axios
			).patch(`/api/accessioner/data/${data._id.$oid}/sample`, {
				form_data: normalizedFormValues,
			});

			toast.dismiss();

			setFormValues(initialFormValues);
			navigate(redirectURL);
		} catch (error) {
			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="FingerPrintIcon"
				heading="Process non-clinic Bladder CARE™ sample"
			>
				{!formValues || !data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<IdOverwriteConfirmationModal
							data={data}
							id={formValues.specimen_id}
							idToOverwrite={'specimen_id'}
							handleUpdateSample={handleSubmit}
							resetId={() =>
								setFormValues((prevValue: FormValues) => {
									if (prevValue) {
										return {
											...prevValue,
											specimen_id:
												data.sample.specimen_id,
										};
									}

									return prevValue;
								})
							}
							idOverwriteConfirmationRef={
								pangeaIdOverwriteConfirmationRef
							}
							visible={{
								visible: showIdOverwriteConfirmationModal,
								setVisible: setShowIdOverwriteConfirmationModal,
							}}
						/>
						<ContentSection id="form">
							<Form>
								<FormGroup heading="Sample information">
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											disabled={true}
											name="pangea_id"
											label="Pangea ID"
											showRequiredAsterisk={true}
											placeholder="e.g., XZ12345678"
											value={formValues['pangea_id']}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-4"></div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="specimen_id"
											label="Specimen ID"
											handleKeyDown={(
												event: KeyboardEvent<HTMLInputElement>
											) => {
												if (event.code === 'Enter')
													event.preventDefault();
											}}
											placeholder="e.g., XZ12345678"
											value={formValues['specimen_id']}
											handleInputChange={handleChange}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="specimen_id"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											showRequiredAsterisk={true}
											name="sample_collection_date"
											label="Sample Collection Date"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample_collection_date'
												]
											}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="sample_collection_date"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											showRequiredAsterisk={true}
											label="Received Date"
											min={
												formValues[
													'sample_collection_date'
												] &&
												formValues[
													'sample_collection_date'
												]
											}
											name="sample_received_date"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample_received_date'
												]
											}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="sample_received_date"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="collection_kit_id"
											label="Collection Kit ID"
											showRequiredAsterisk={true}
											placeholder="e.g., XZ12345678"
											value={
												formValues['collection_kit_id']
											}
											handleKeyDown={(
												event: KeyboardEvent<HTMLInputElement>
											) => {
												if (event.code === 'Enter')
													event.preventDefault();
											}}
											handleInputChange={handleChange}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="collection_kit_id"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="tracking_number"
											label="Tracking Number"
											placeholder="e.g., XZ12345678"
											value={
												formValues['tracking_number']
											}
											handleKeyDown={(
												event: KeyboardEvent<HTMLInputElement>
											) => {
												if (event.code === 'Enter')
													event.preventDefault();
											}}
											handleInputChange={handleChange}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="tracking_number"
												/>
											}
										/>
									</div>
								</FormGroup>
								<FormGroup heading="Optional information">
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											label="First Name"
											name="misc.added_fields.first_name"
											placeholder="e.g., John"
											value={
												formValues[
													'misc.added_fields.first_name'
												]
											}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											label="Last Name"
											name="misc.added_fields.last_name"
											placeholder="e.g., Smith"
											value={
												formValues[
													'misc.added_fields.last_name'
												]
											}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											name="misc.added_fields.date_of_birth"
											label="Date of Birth"
											handleInputChange={handleChange}
											value={
												formValues[
													'misc.added_fields.date_of_birth'
												]
											}
										/>
									</div>
								</FormGroup>
								<FormFooter className="flex items-center justify-between">
									<TextButton
										color="gray"
										type="button"
										onClick={() => {
											navigate(
												redirectPathQuery
													? redirectPathQuery
													: `/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess${redirectPathQuery}`
											);
										}}
										text={<span>&#8592; Back</span>}
									/>
									<Button
										type="button"
										tier="tertiary"
										disabled={disabled}
										onClick={handleSubmit}
										text="Save and continue"
									/>
								</FormFooter>
							</Form>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
