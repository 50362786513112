import { useState, Fragment, SetStateAction, Dispatch } from 'react';

import { ContentSection, DescriptionList } from '@pangea-lis-apps/ui';

import {
	formatDate,
	Option,
	Attribute,
	initialAttributeValue,
	FORMS,
} from '@pangea-lis-apps/utils';

import {
	UpdateNonResultModal,
	ClientServiceDescriptionItem,
} from './components';

interface ISampleSection {
	data: any;
	originalData?: any;
	payload: any;

	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function SampleSection(props: ISampleSection) {
	const [modalVisible, setModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'select' | 'date' | 'multiple_select' | 'file' | 'email',
		selectOptions?: Option[],
		selectLabel?: string,
		validateFn?: (input: string) => { status: boolean; errMessage: string }
	) => {
		setAttribute({
			property,
			value,
			type,
			label,
			selectOptions,
			selectLabel,
			validateFn,
		});

		setModalVisible(true);
	};

	const validReceiveDateAndCollectionDate = (
		input: string
	): { status: boolean; errMessage: string } => {
		const collectionDate = props.data.sample_collection_date;
		if (
			collectionDate === undefined ||
			collectionDate === null ||
			collectionDate === ''
		)
			return { status: true, errMessage: '' };

		if (
			parseInt(collectionDate.replace(/-/g, '')) <=
			parseInt(input.replace(/-/g, ''))
		) {
			return { status: true, errMessage: '' };
		}

		return {
			status: false,
			errMessage:
				'Receive date should not be earlier than collection date',
		};
	};

	const validCollectionDate = (
		input: string
	): { status: boolean; errMessage: string } => {
		const receiveDate = props.data.sample_received_date;
		if (
			receiveDate === undefined ||
			receiveDate === null ||
			receiveDate === ''
		)
			return { status: true, errMessage: '' };

		if (
			parseInt(receiveDate.replace(/-/g, '')) >=
			parseInt(input.replace(/-/g, ''))
		) {
			return { status: true, errMessage: '' };
		}

		return {
			status: false,
			errMessage:
				'Receive date should not be earlier than collection date',
		};
	};

	return (
		<Fragment>
			<UpdateNonResultModal
				data={props.data}
				attribute={attribute}
				setRefresh={props.setRefresh}
				heading="Edit info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<ContentSection heading="Sample Info">
				<DescriptionList>
					{props.data.type === 'non_clinic' && (
						<Fragment>
							<ClientServiceDescriptionItem
								withFunction
								term="RUO"
								Icon="PencilIcon"
								details={props.data.ruo}
								originalDetails={props.originalData.ruo}
								handleClick={() =>
									handleClick(
										'ruo',
										props.data.ruo,
										'RUO',
										'select',
										FORMS.yes_no_options,
										'Is RUO?'
									)
								}
							/>
							<div className="sm:col-span-2"></div>
						</Fragment>
					)}
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Specimen ID"
						details={props.data.specimen_id}
						originalDetails={props.originalData.specimen_id}
						handleClick={() =>
							handleClick(
								'specimen_id',
								props.data.specimen_id,
								'Specimen ID',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Collection Kit ID"
						details={props.data.collection_kit_id}
						originalDetails={props.originalData.collection_kit_id}
						handleClick={() =>
							handleClick(
								'collection_kit_id',
								props.data.collection_kit_id,
								'Collection Kit ID',
								'text'
							)
						}
					/>
					<div className="sm:col-span-1"></div>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Collection Date"
						details={formatDate(props.data.sample_collection_date)}
						originalDetails={formatDate(
							props.originalData.sample_collection_date
						)}
						handleClick={() =>
							handleClick(
								'sample_collection_date',
								formatDate(props.data.sample_collection_date) ||
									'-',
								'Collection Date',
								'date',
								undefined,
								undefined,
								validCollectionDate
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Received Date"
						details={formatDate(props.data.sample_received_date)}
						originalDetails={formatDate(
							props.originalData.sample_received_date
						)}
						handleClick={() =>
							handleClick(
								'sample_received_date',
								formatDate(props.data.sample_received_date) ||
									'-',
								'Received Date',
								'date',
								undefined,
								undefined,
								validReceiveDateAndCollectionDate
							)
						}
					/>
				</DescriptionList>
			</ContentSection>
		</Fragment>
	);
}
