/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	Fragment,
	useState,
	useEffect,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	useAxios,
	FormGroup,
	DateField,
	LoadingBox,
	OptionCard,
	SelectField,
	GenericField,
	ContentSection,
	MultipleCombobox,
	OptionCardContainer,
} from '@pangea-lis-apps/ui';
import { STI, FORMS, Option, formatDate } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { handleComboboxSelect } from '../../../../helpers/form';
import SearchPatientForm from '../../components/search-patient-form';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';

const STI_V1_0 = STI['1.0'];

interface FormValues {
	patient_last_menstrual_date: string;
	patient_first_name: string;
	patient_last_name: string;
	patient_middle_name: string;
	patient_address: string;
	patient_address2: string;
	patient_city: string;
	patient_country: string;
	patient_race: Option[];
	patient_ethnicity: string;
	patient_state: string;
	patient_zip_code: string;
	patient_date_of_birth: string;
	patient_sex: string;
	patient_phone_number: string;
	patient_medical_record_number: string;
	flag: {
		patient_last_menstrual_date: boolean;
		patient_first_name: boolean;
		patient_last_name: boolean;
		patient_middle_name: boolean;
		patient_address: boolean;
		patient_address2: boolean;
		patient_city: boolean;
		patient_race: boolean;
		patient_ethnicity: boolean;
		patient_state: boolean;
		patient_country: boolean;
		patient_zip_code: boolean;
		patient_date_of_birth: boolean;
		patient_sex: boolean;
		patient_phone_number: boolean;
		patient_medical_record_number: boolean;
	};
}

const initialFormValues = {
	patient_last_menstrual_date: '',
	patient_first_name: '',
	patient_middle_name: '',
	patient_last_name: '',
	patient_address: '',
	patient_address2: '',
	patient_city: '',
	patient_race: [],
	patient_ethnicity: '',
	patient_state: '',
	patient_zip_code: '',
	patient_date_of_birth: '',
	patient_country: '',
	patient_sex: '',
	patient_phone_number: '',
	patient_medical_record_number: '',
	flag: {
		patient_last_menstrual_date: false,
		patient_first_name: false,
		patient_last_name: false,
		patient_middle_name: false,
		patient_address: false,
		patient_address2: false,
		patient_city: false,
		patient_race: false,
		patient_ethnicity: false,
		patient_state: false,
		patient_zip_code: false,
		patient_country: false,
		patient_date_of_birth: false,
		patient_sex: false,
		patient_phone_number: false,
		patient_medical_record_number: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PatientInfoProps {}

export default function PatientInfo(props: PatientInfoProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const [disabled, setDisabled] = useState(false);

	const {
		data,
		formId,
		setRefresh,
		formValues,
		handleSubmit,
		formValuesRef,
		setFormValues,
	} = useContext(ProcessTRFStepsContext);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const [stateSelection, setStateSelection] = useState('');

	useEffect(() => {
		if (formValues && !stateSelection) {
			if (
				formValues['patient_state'] !== '' &&
				!FORMS.states
					.map((option: Option) => option.value)
					.includes(formValues.patient_state)
			) {
				setStateSelection('other');
			} else {
				setStateSelection(formValues.patient_state);
			}
		}
	}, [formValues, stateSelection]);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					const value = target.value;

					if (target.name === 'patient_sex')
						prevValues.patient_last_menstrual_date = '';

					return {
						...prevValues,
						[target.name]: value,
					};
				}

				return prevValues;
			});
	};

	const handleUnlinkProfile = async () => {
		if (!axios || !data || disabled) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accessioner/data/${data._id.$oid}/trf/identify-patient`,
				{
					patient_id: null,
				}
			);

			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
		toast.dismiss();
	};

	const handleStateSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setStateSelection(target.value);
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return !(formValues && data) ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<SearchPatientForm data={data} setRefresh={setRefresh} />

			<hr className="mt-8 mb-6" />

			<Form id={formId} handleSubmit={handleSubmit}>
				<FormGroup heading="Patient Info">
					{data && data.patient && (
						<div className="sm:col-span-6">
							<Alert
								type="info"
								heading="Saved patient profile"
								description={
									<span>
										A patient profile for{' '}
										<span className="italic font-semibold">
											{data.patient.first_name}{' '}
											{data.patient.last_name}{' '}
											{formatDate(
												data.patient.date_of_birth
											)}
										</span>{' '}
										is associated with this requisition
										form. Edits made to the patient info in
										the following form will update the
										associated patient profile. To create a
										new patient profile,{' '}
										<button
											type="button"
											className="underline"
											onClick={handleUnlinkProfile}
										>
											unlink
										</button>{' '}
										the profile and enter in the new patient
										details.
									</span>
								}
							></Alert>
						</div>
					)}

					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_first_name"
							label="First Name"
							showRequiredAsterisk={true}
							placeholder="e.g., Sarah"
							value={formValues.patient_first_name}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_first_name"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Middle Name"
							placeholder="e.g., Taylor"
							name="patient_middle_name"
							handleInputChange={handleChange}
							value={formValues.patient_middle_name}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_middle_name"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_last_name"
							label="Last Name"
							showRequiredAsterisk={true}
							placeholder="e.g., Chang"
							value={formValues.patient_last_name}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_last_name"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<DateField
							label="Date of Birth"
							name="patient_date_of_birth"
							showRequiredAsterisk={true}
							handleInputChange={handleChange}
							value={formValues.patient_date_of_birth}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_date_of_birth"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<MultipleCombobox
							label="Race"
							name="patient_race"
							value={formValues.patient_race}
							handleSelect={(selectedOptions: Option[]) => {
								handleComboboxSelect(
									selectedOptions,
									setFormValues,
									'patient_race'
								);
							}}
							options={STI_V1_0.race_options}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_race"
								/>
							}
						/>
					</div>
					{formValues.patient_race &&
					formValues.patient_race.length > 0 ? (
						<Fragment>
							<div className="sm:col-span-2">
								<OptionCardContainer heading="Selected race(s)">
									{formValues.patient_race.map(
										(option: Option, index: number) => (
											<OptionCard
												key={index}
												option={option}
												handleRemove={(
													selectedTest: string
												) => {
													setFormValues(
														(
															prevVal: FormValues
														) => {
															if (prevVal)
																return {
																	...prevVal,
																	patient_race:
																		prevVal.patient_race.filter(
																			(
																				option: Option
																			) =>
																				option.value !==
																				selectedTest
																		),
																};

															return prevVal;
														}
													);
												}}
											/>
										)
									)}
								</OptionCardContainer>
							</div>
							<div className="sm:col-span-2"></div>
						</Fragment>
					) : (
						<div className="sm:col-span-4"></div>
					)}
					<div className="sm:col-span-2">
						<SelectField
							label="Ethnicity"
							name="patient_ethnicity"
							handleSelect={handleChange}
							value={formValues.patient_ethnicity}
							options={STI_V1_0.ethnicity_options}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_ethnicity"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<SelectField
							label="Sex"
							name="patient_sex"
							showRequiredAsterisk={true}
							value={formValues.patient_sex}
							handleSelect={handleChange}
							options={FORMS.sex_options}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_sex"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<DateField
							showRequiredAsterisk={true}
							label="Last menstrual date"
							name="patient_last_menstrual_date"
							handleInputChange={handleChange}
							disabled={formValues.patient_sex === 'male'}
							value={formValues.patient_last_menstrual_date}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_last_menstrual_date"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Phone Number"
							showRequiredAsterisk={true}
							name="patient_phone_number"
							placeholder="e.g., +1 (234) 567-8901"
							value={formValues.patient_phone_number}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_phone_number"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							placeholder="e.g., XZ1234567890"
							handleInputChange={handleChange}
							label="Medical Record Number (MRN)"
							name="patient_medical_record_number"
							value={formValues.patient_medical_record_number}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_medical_record_number"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-4">
						<GenericField
							type="text"
							name="patient_address"
							label="Address"
							showRequiredAsterisk={true}
							placeholder="e.g., 123 Main St."
							value={formValues.patient_address}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_address"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-4">
						<GenericField
							type="text"
							name="patient_address2"
							label="Address Line 2"
							placeholder="e.g., Suite 101"
							value={formValues.patient_address2}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_address2"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_city"
							label="City"
							showRequiredAsterisk={true}
							placeholder="e.g., Albany"
							value={formValues.patient_city}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_city"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="State"
							name="patient_state"
							options={FORMS.states}
							showRequiredAsterisk={true}
							handleSelect={handleStateSelectionChange}
							value={stateSelection}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_state"
								/>
							}
						/>
					</div>
					{stateSelection === 'other' ? (
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="If other, please specify"
								name="patient_state"
								placeholder="e.g., New York"
								value={formValues.patient_state}
								handleInputChange={handleChange}
							/>
						</div>
					) : (
						<div className="sm:col-span-2"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_zip_code"
							label="ZIP Code"
							showRequiredAsterisk={true}
							placeholder="e.g., 18279"
							value={formValues.patient_zip_code}
							handleInputChange={handleChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_zip_code"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="Country"
							name="patient_country"
							options={FORMS.countries}
							showRequiredAsterisk={true}
							handleSelect={handleChange}
							value={formValues.patient_country}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_country"
								/>
							}
						/>
					</div>
				</FormGroup>
			</Form>
		</ContentSection>
	);
}
