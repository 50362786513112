/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useRef,
	Fragment,
	useState,
	FormEvent,
	useContext,
	ChangeEvent,
	KeyboardEvent,
	ChangeEventHandler,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	DateField,
	TextButton,
	FormFooter,
	SelectField,
	GenericField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { ASSAYS, BAR_ACTIONS, ORGANIZATIONS } from '@pangea-lis-apps/utils';

import { ProcessSampleContext } from '../process-sample';
import {
	usePopulateFormValues,
	normalizeAccessioningFormValues,
} from '../../trf/utils/helpers';
import FlagButton from '../../../../../../../components/flag-button';
import IdOverwriteConfirmationModal from '../../../helpers/specimen-id-overwrite-confirmation-modal';

interface FormValues {
	priority: string;
	specimen_id: string;
	tracking_number: string;
	sample_received_date: string;
	sample_transport_storage_condition: string;
	flag: {
		priority: boolean;
		tracking_number: boolean;
		sample_received_date: boolean;
		sample_transport_storage_condition: boolean;
	};
}

const initialFormValues = {
	priority: '',
	specimen_id: '',
	tracking_number: '',
	sample_received_date: '',
	sample_transport_storage_condition: '',
	flag: {
		priority: false,
		tracking_number: false,
		sample_received_date: false,
		sample_transport_storage_condition: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Resp4PlexSampleUpdateProps {}

export default function Resp4PlexSampleUpdate(
	props: Resp4PlexSampleUpdateProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { assay, dataId } = useParams();
	const { data } = useContext(ProcessSampleContext);

	const formValuesRef = useRef<FormValues>();
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'sample'
	);

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const specimenIdOverwriteConfirmationRef = useRef(false);
	const [
		showIdOverwriteConfirmationModal,
		setShowIdOverwriteConfirmationModal,
	] = useState(false);

	const handleUpdateSample = async (event?: FormEvent) => {
		if (event) event.preventDefault();

		const redirectURL = `/accessioner/accession/sample/${assay}/data/${dataId}/sample/print-barcode${redirectPathQuery}`;

		if (disabled || !axios || !data) return;
		else if (
			JSON.stringify(formValuesRef.current) === JSON.stringify(formValues)
		) {
			navigate(redirectURL);
			return;
		} else if (
			data.sample.specimen_id &&
			formValues['specimen_id'] !== data.sample.specimen_id
		) {
			if (!specimenIdOverwriteConfirmationRef.current) {
				setShowIdOverwriteConfirmationModal(true);
				return;
			}

			specimenIdOverwriteConfirmationRef.current = false;
			setShowIdOverwriteConfirmationModal(false);
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'sample'
			);

			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample`, {
				user,
				assay,
				form_data: normalizedFormValues,
			});

			toast.dismiss();

			navigate(redirectURL);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues) => {
				return {
					...prevValues,
					[target.name]: target.value,
				};
			});
	};

	return data ? (
		<Fragment>
			<IdOverwriteConfirmationModal
				data={data}
				idToOverwrite="specimen_id"
				id={formValues['specimen_id']}
				resetId={() =>
					setFormValues((prevValues) => ({
						...prevValues,
						specimen_id: '',
					}))
				}
				handleUpdateSample={handleUpdateSample}
				idOverwriteConfirmationRef={specimenIdOverwriteConfirmationRef}
				visible={{
					visible: showIdOverwriteConfirmationModal,
					setVisible: setShowIdOverwriteConfirmationModal,
				}}
			/>

			<ContentSection
				heading="Update sample info"
				className="bg-gray-50 border border-gray-200 py-8 rounded-lg"
			>
				<Form handleSubmit={handleUpdateSample}>
					<FormGroup>
						<div className="sm:col-span-2">
							<DateField
								showRequiredAsterisk={true}
								label="Received Date"
								min={
									data.sample.sample_collection_date &&
									(data.sample
										.sample_collection_date as string)
								}
								name="sample_received_date"
								handleInputChange={handleChange}
								value={formValues['sample_received_date']}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="sample_received_date"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Tracking Number"
								name="tracking_number"
								placeholder="e.g., XZ12345678"
								handleKeyDown={(
									event: KeyboardEvent<HTMLInputElement>
								) => {
									if (event.code === 'Enter')
										event.preventDefault();
								}}
								value={formValues['tracking_number']}
								handleInputChange={handleChange}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="tracking_number"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Specimen ID"
								name="specimen_id"
								handleKeyDown={(
									event: KeyboardEvent<HTMLInputElement>
								) => {
									if (event.code === 'Enter')
										event.preventDefault();
								}}
								placeholder="e.g., XZ12345678"
								value={formValues['specimen_id']}
								handleInputChange={handleChange}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="specimen_id"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								name="priority"
								handleSelect={handleChange}
								label="Change Priority Level?"
								value={formValues['priority']}
								options={ORGANIZATIONS.priorityLevels}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<SelectField
								required
								handleSelect={handleChange}
								options={
									ASSAYS['resp4plex']
										.transport_storage_conditions
								}
								label="Transport Storage Condition?"
								name="sample_transport_storage_condition"
								value={
									formValues[
										'sample_transport_storage_condition'
									]
								}
								disabled={
									Boolean(data.sample.sample_received_date) &&
									BAR_ACTIONS.includes(data.sample.status)
								}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="sample_transport_storage_condition"
									/>
								}
							/>
						</div>
					</FormGroup>
					<FormFooter className="!justify-between">
						<TextButton
							color="gray"
							type="button"
							onClick={() =>
								navigate(
									`/accessioner/accession/trf/resp4plex/v${data.requisition_form.metadata.version}/data/${dataId}/trf/review`
								)
							}
							text={
								<span>
									&#8592; Return to{' '}
									<span className="italic">Process TRF</span>
								</span>
							}
						/>
						<Button
							type="submit"
							tier="tertiary"
							text="Continue"
							disabled={disabled}
							Icon="ArrowSmallRightIcon"
						/>
					</FormFooter>
				</Form>
			</ContentSection>
		</Fragment>
	) : null;
}
