import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as Heroicons from '@heroicons/react/24/solid';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Alert,
	Modal,
	Button,
	useAxios,
	DateField,
	FormFooter,
	LoadingBox,
	SelectField,
	GenericField,
	TextareaField,
	DescriptionItem,
	DescriptionTerm,
	DescriptionDetails,
	MultipleSelectField,
	DescriptionItemProps,
} from '@pangea-lis-apps/ui';
import { IPayLoad } from '../data-types';
import { Date, Attribute } from '@pangea-lis-apps/utils';

export interface IClientServiceDescriptionItemProps
	extends DescriptionItemProps {
	originalDetails?:
		| string
		| number
		| Date
		| boolean
		| undefined
		| string[]
		| null;
}

export function ClientServiceDescriptionItem(
	props: IClientServiceDescriptionItemProps
) {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	const formattedDetails = (details: any) => {
		if (typeof details === 'boolean') {
			return details ? 'Yes' : 'No';
		} else if (!details || details === undefined || details === null) {
			return 'N/A';
		} else if (typeof details === 'object' && '$date' in details) {
			return details.$date;
		} else if (typeof details === 'string') {
			if (details === 'true') return 'Yes';
			else if (details === 'false') return 'No';
		}

		return details;
	};

	return (
		<div
			className={
				props.customColSpan ? props.customColSpan : 'sm:col-span-1'
			}
		>
			<div className="flex items-center">
				<DescriptionTerm className={props.termStyles}>
					{props.term}
				</DescriptionTerm>
				{props.withFunction && (
					<Icon
						aria-hidden="true"
						onClick={props.handleClick}
						className="ml-1 h-3 w-3 text-gray-400 hover:text-gray-500 cursor-pointer"
					/>
				)}
			</div>
			<DescriptionDetails>
				<span>
					{(() => {
						if (props.children) return props.children;

						return formattedDetails(props.details);
					})()}
				</span>
				{props.originalDetails !== props.details ? (
					<span className="text-red-600 font-medium pl-1">
						← {formattedDetails(props.originalDetails)}
					</span>
				) : (
					''
				)}
			</DescriptionDetails>
		</div>
	);
}

export interface UpdateNonResultModalProps {
	data: any;
	heading: string;
	subheading?: string;
	attribute: Attribute;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	customWidth?: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function UpdateNonResultModal(props: UpdateNonResultModalProps) {
	const { visible, setVisible } = props.visible;
	const [selectedOption, setSelectedOption] = useState('');
	const [newValue, setNewValue] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [multipleSelectNewValue, setMultipleSelectNewValue] = useState<
		string[]
	>([]);

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;
		if (target && target.name) setNewValue(target.value);
	};

	const handleSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setSelectedOption(target.value);
			setNewValue(target.value === 'other' ? '' : target.value);
		}
	};

	const handleMultipleSelectChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name)
			setMultipleSelectNewValue(
				Array.from(target.selectedOptions, (item) => item.value)
			);
	};

	const handleCloseModal = () => {
		setNewValue('');
		setVisible(false);
		setDisabled(false);
		setSelectedOption('');
	};

	const handleSubmit = () => {
		// If has validateFn
		if (props.attribute.validateFn) {
			const { status, errMessage } = props.attribute.validateFn(newValue);

			// Not Pass Validate fn return with error message
			if (status === false) {
				toast.error(errMessage);
				return;
			}
		}

		// If Pass validateFn keep going for the rest of code
		props.data[props.attribute.property] = newValue;
		props.setRefresh(true);
		handleCloseModal();
	};

	return (
		<Modal
			visible={visible}
			title={props.heading}
			onClose={handleCloseModal}
			description={props.subheading}
			customWidth={props.customWidth ? props.customWidth : 'w-92'}
		>
			<form autoComplete="off">
				<div className="space-y-4">
					<DescriptionItem
						term={props.attribute.label}
						details={
							props.attribute.selectLabel
								? props.attribute.selectLabel
								: props.attribute.value
						}
					/>
					{(() => {
						const { type, selectOptions } = props.attribute;

						if (type === 'select') {
							return (
								<Fragment>
									<SelectField
										required
										name="new_value"
										label="New value"
										value={selectedOption}
										options={selectOptions || []}
										handleSelect={handleSelectionChange}
									/>
									{selectedOption === 'other' && (
										<GenericField
											required
											type="text"
											name="new_value"
											value={newValue}
											label="If other, please specify"
											placeholder="Enter other value"
											handleInputChange={
												handleInputChange
											}
										/>
									)}
								</Fragment>
							);
						} else if (type === 'multiple_select') {
							return (
								<MultipleSelectField
									required
									name="new_value"
									label="New value"
									options={selectOptions || []}
									value={multipleSelectNewValue}
									handleSelect={handleMultipleSelectChange}
								/>
							);
						} else if (type === 'date') {
							return (
								<DateField
									required
									name="new_value"
									value={newValue}
									label="New value"
									handleInputChange={handleInputChange}
								/>
							);
						} else if (type === 'textarea') {
							return (
								<TextareaField
									required
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter text"
									handleInputChange={handleInputChange}
								/>
							);
						} else {
							return (
								<GenericField
									required
									type={type}
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter value"
									handleInputChange={handleInputChange}
								/>
							);
						}
					})()}
				</div>
			</form>
			<FormFooter>
				<Button
					type="submit"
					text="Update"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={disabled}
					onClick={() => handleSubmit()}
				/>
			</FormFooter>
		</Modal>
	);
}

interface CommentsModal {
	attribute: object;
	data: IPayLoad;
	subheading?: string;
	heading: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	customWidth?: string;
}

export function CommentsModal(props: CommentsModal) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const { dataId } = useParams();

	const { visible, setVisible } = props.visible;
	const [newValue, setNewValue] = useState(props.data.revision_comment);
	const [disabled, setDisabled] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setNewValue(target.value);
		}
	};

	const handleCloseModal = () => {
		setVisible(false);
		setDisabled(false);
	};

	const handleSubmit = async () => {
		props.data.revision_comment = newValue;

		if (!axios) return;

		setDisabled(true);
		setShowLoading(true);

		try {
			await (
				await axios
			).patch(`/api/client-services/data/${dataId}/report`, props.data);

			toast.success('Update successful!');
			props.setRefresh(true);
		} catch (err) {
			console.log(err);
			toast.error('Update Failed.');
		} finally {
			setShowLoading(false);
			setDisabled(false);
			setVisible(false);
		}
	};

	useEffect(() => {
		setNewValue(props.data.revision_comment);
	}, [props]);

	return (
		<Modal
			visible={visible}
			title={props.heading}
			onClose={handleCloseModal}
			description={props.subheading}
			customWidth={props.customWidth ? props.customWidth : 'w-140'}
		>
			<form>
				<Alert
					type="warning"
					className="mb-4"
					heading="New report will be generated"
					description="The report has already been verified. A new report will be generated with the provided comment."
				/>

				{showLoading ? (
					<LoadingBox />
				) : (
					<TextareaField
						required
						name="new_value"
						value={newValue}
						label="New Comments"
						placeholder={
							props.data ? props.data.revision_comment : ''
						}
						handleInputChange={handleInputChange}
					/>
				)}
			</form>

			<FormFooter>
				<Button
					type="submit"
					text="Submit"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={disabled}
					onClick={() => handleSubmit()}
				/>
			</FormFooter>
		</Modal>
	);
}
