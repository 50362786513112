import { IconName } from '../../heroicons';
import { OrganizationRoleID } from '../roles';
import { ID, Date, User, Metadata, ActionMetadata } from '../../common-types';

export interface NavigationTabRequest {
	url: string;
	method: 'post';
	payload: {
		[key: string]: string | boolean;
	};
}

export interface NavigationTabChild {
	id: string;
	href: string;
	label: string;
	hide?: boolean;
	request?: NavigationTabRequest;
}

export interface NavigationItem {
	entity: string;
	label: string;
	corresponding_role: string;
	tabs: NavigationTab[];
}

export interface NavigationTab {
	id: string;
	href: string;
	label: string;
	hide?: boolean;
	icon?: IconName;
	request?: NavigationTabRequest;
	children?: NavigationTabChild[];
	required_permission_for_staff: string;
}

export type BarAction = 'flag' | 'hold' | 'review';
export const BAR_ACTIONS = ['flag', 'hold', 'needs_review', 'reviewed'];

export type DataReference = null | {
	_id: ID;
	pangea_id: string;
	specimen_id: string;
	collection_date: string;
	patient_last_name: string;
	patient_first_name: string;
	patient_date_of_birth: string;
	status: 'approve' | 'flag' | 'hold';
};

export interface Well {
	occupied_status: number;
	data_reference: DataReference;
}

export interface Rack {
	_id: ID;
	rows: number;
	type: 'hold' | 'flag';
	name: string;
	columns: number;
	number: number;
	sequence: Well[];
	metadata: Metadata;
}

export interface SelectedWell {
	index: number;
	location: string;
	deselect: boolean;
	rack_name: string;
}

export interface MessageProps {
	id: string;
	content: string;
	metadata: ActionMetadata;
}

export interface SampleAccessioningApproval {
	occupied_status?: number;
	approve: {
		value: boolean;
		metadata: ActionMetadata;
	};
	hold: {
		reviewed: {
			value: boolean;
			metadata: ActionMetadata;
		};
		selected_well: SelectedWell;
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
	};
	needs_review: {
		previous_status: string;
		selected_well: SelectedWell;
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
	};
	reviewed: {
		reject: boolean;
		metadata: ActionMetadata;
	};
	flag: {
		selected_well: SelectedWell;
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
		thread: {
			messages: MessageProps[];
			customer_response_required: boolean;
			accessioner_response_required: boolean;
		};
	};
	reject: {
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
		customer_resolved: {
			value: boolean;
			metadata: ActionMetadata;
		};
	};
}

export interface NonClinicSampleAccessioningApproval {
	approve: {
		value: boolean;
		metadata: ActionMetadata;
	};
	needs_review: {
		previous_status: string;
		selected_well: SelectedWell;
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
	};
	reviewed: {
		reject: boolean;
		metadata: ActionMetadata;
	};
	flag: {
		selected_well: SelectedWell;
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
		thread: {
			messages: MessageProps[];
			customer_response_required: boolean;
			accessioner_response_required: boolean;
		};
	};
	reject: {
		reason: {
			value: string;
			metadata: ActionMetadata;
		};
		customer_resolved: {
			value: boolean;
			metadata: {
				by: User;
				date: Date;
			};
		};
	};
}

export interface ExtendedPermission {
	permissions: string[];
	associate: { $oid: string };
}

export interface HydratedExtendedPermission {
	permissions: string[];
	associate: BaseRawCustomer;
}

export interface BaseCustomer {
	_id: ID;
	roles: OrganizationRoleID[];
	entity: string;
	title: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	email_address: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	metadata: Metadata;
	phone_number: string;
	outbound_emails: string[];
	account_activated: boolean;
	billing_methods: CustomerBillingMethod[];
	extended_permissions: ExtendedPermission[];
	national_provider_identifier: string | undefined;
}

export interface CustomerBillingMethod {
	_id: ID;
	client_name: string;
	client_institution: string;
	client_address: string;
	client_city: string;
	client_state: string;
	client_zip_code: string;
	client_country: string;
	client_phone_number: string;
	client_fax_number: string;
	client_email_address: string;
	billing_contact_name: string;
	billing_contact_institution: string;
	billing_contact_address: string;
	billing_contact_city: string;
	billing_contact_state: string;
	billing_contact_zip_code: string;
	billing_contact_country: string;
	billing_contact_phone_number: string;
	billing_contact_fax_number: string;
	billing_contact_email_address: string;
}

export interface BaseRawCustomer extends BaseCustomer {
	organization: {
		_id: ID;
	};
}

export interface BaseHydratedCustomer extends BaseCustomer {
	organization: Organization;
}

export type Entity = 'clinic' | 'non_clinic';

export interface BaseOrganization {
	_id: ID;
	name: string;
	entity: Entity;
	priority: string;
	logo_image: string;
	email_address: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	phone_number: string;
	metadata: Metadata;
	national_provider_identifier?: string;
}

export type Organization = BaseOrganization;

export interface Note {
	id: string;
	edited: boolean;
	content: string;
	metadata: Metadata;
	sending_department?: string;
	receiving_departments?: string[];
}

export interface Auth0User {
	user_id: string;
	email: string;
	email_verified: boolean;
	username: string;
	phone_number: string;
	phone_verified: boolean;
	created_at: string;
	updated_at: string;
	identities: {
		connection: string;
		user_id: string;
		provider: string;
		isSocial: boolean;
	}[];
	app_metadata: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	user_metadata: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	picture: string;
	name: string;
	nickname: string;
	multifactor: string[];
	last_ip: string;
	last_login: string;
	logins_count: number;
	blocked: boolean;
	given_name: string;
	family_name: string;
}

export interface Auth0UserWithRoles extends Auth0User {
	roles: Auth0Role[];
}

export interface Auth0Role {
	id: string;
	name: string;
	description: string;
}

export interface Auth0RoleWithPermissions {
	description: string;
	id: string;
	name: string;
	permissions: Auth0Permission[];
}

export interface Auth0Permission {
	resource_server_identifier: string;
	permission_name: string;
	resource_server_name: string;
	description: string;
}

export interface LogChange {
	property: string;
	new_value: any;
	old_value: any;
}

export interface ActionLog {
	note: string;
	agent: User;
	date: Date;
}

export interface Log {
	reference_id: ID;
	date_created: Date;
	agent: User;
	action: string;
	notes: string;
	changes?: LogChange[];
}

interface PermissionGroup {
	assignable_to: string[];
	id: string;
	name: string;
	description: string;
	permissions: {
		name: string;
		description: string;
	};
}

export interface Scope {
	permission_groups: PermissionGroup[];
}

export interface Message {
	_id: ID;
	message: string;
	subject: string;
	resolved: boolean;
	thread: MessageProps[];
	metadata: Metadata & {
		resolved_by: User;
		customer_response_required: boolean;
		associate_response_required: boolean;
	};
	customer: BaseHydratedCustomer;
}

export interface Patient {
	_id: ID;
	customers: BaseRawCustomer[];
	email_address: string | undefined;
	first_name: string;
	middle_name: string;
	last_name: string;
	address: string;
	city: string;
	state: string;
	country: string;
	zip_code: string;
	race: string[];
	ethnicity: string;
	date_of_birth: string;
	sex: string;
	phone_number: string;
	medical_record_number: string;
	metadata: Metadata;
}

export interface DateRange {
	date_end: string;
	date_start: string;
}

export interface Tab {
	label: string;
	value: string;
	show?: boolean;
}

export interface SubmitEvent extends Event {
	readonly submitter: HTMLElement;
}

export interface ActionHistory {
	_id: ID;
	history: ActionLog[];
}
